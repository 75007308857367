export default {
  countryCodes: {
    // af: 'Afghanistan',
    // ax: 'Åland Islands',
    // al: 'Albania',
    // dz: 'Algeria',
    // as: 'American Samoa',
    // ad: 'Andorra',
    // ao: 'Angola',
    // ai: 'Anguilla',
    // ag: 'Antigua',
    // ar: 'Argentina',
    // am: 'Armenia',
    // aw: 'Aruba',
    // au: 'Australia',
    // at: 'Austria',
    // az: 'Azerbaijan',
    // bs: 'Bahamas',
    // bh: 'Bahrain',
    // bd: 'Bangladesh',
    // bb: 'Barbados',
    // by: 'Belarus',
    // be: 'Belgium',
    // bz: 'Belize',
    // bj: 'Benin',
    // bm: 'Bermuda',
    // bt: 'Bhutan',
    // bo: 'Bolivia',
    // ba: 'Bosnia',
    // bw: 'Botswana',
    // br: 'Brazil',
    // vg: 'British Virgin Islands',
    // bn: 'Brunei',
    // bg: 'Bulgaria',
    // bf: 'Burkina Faso',
    // mm: 'Burma',
    // bi: 'Burundi',
    // tc: 'Caicos Islands',
    // kh: 'Cambodia',
    // cm: 'Cameroon',
    // ca: 'Canada',
    // cv: 'Cape Verde',
    // ky: 'Cayman Islands',
    // cf: 'Central African Republic',
    // td: 'Chad',
    // cl: 'Chile',
    // cn: 'China',
    // cx: 'Christmas Island',
    // cc: 'Cocos Islands',
    co: 'Colombia',
    cw: 'Curacao',
    // km: 'Comoros',
    // cg: 'Congo Brazzaville',
    // cd: 'Congo',
    // ck: 'Cook Islands',
    // cr: 'Costa Rica',
    // ci: 'Cote Divoire',
    // hr: 'Croatia',
    // cu: 'Cuba',
    // cy: 'Cyprus',
    // cz: 'Czech Republic',
    // dk: 'Denmark',
    // dj: 'Djibouti',
    // dm: 'Dominica',
    // do: 'Dominican Republic',
    // ec: 'Ecuador',
    // eg: 'Egypt',
    // sv: 'El Salvador',
    // gb: 'England',
    // gq: 'Equatorial Guinea',
    // er: 'Eritrea',
    // ee: 'Estonia',
    // et: 'Ethiopia',
    // fk: 'Falkland Islands',
    // fo: 'Faroe Islands',
    // fj: 'Fiji',
    // fi: 'Finland',
    // fr: 'France',
    // gf: 'French Guiana',
    // pf: 'French Polynesia',
    // ga: 'Gabon',
    // gm: 'Gambia',
    // ge: 'Georgia',
    // de: 'Germany',
    // gh: 'Ghana',
    // gi: 'Gibraltar',
    // gr: 'Greece',
    // gl: 'Greenland',
    // gd: 'Grenada',
    // gp: 'Guadeloupe',
    // gu: 'Guam',
    // gt: 'Guatemala',
    // gw: 'Guinea-Bissau',
    // gn: 'Guinea',
    // gy: 'Guyana',
    // ht: 'Haiti',
    // hn: 'Honduras',
    // hk: 'Hong Kong',
    // hu: 'Hungary',
    // is: 'Iceland',
    // in: 'India',
    // io: 'Indian Ocean Territory',
    // id: 'Indonesia',
    // ir: 'Iran',
    // iq: 'Iraq',
    // ie: 'Ireland',
    // il: 'Israel',
    // it: 'Italy',
    // jm: 'Jamaica',
    // jp: 'Japan',
    // jo: 'Jordan',
    // kz: 'Kazakhstan',
    // ke: 'Kenya',
    // ki: 'Kiribati',
    // kw: 'Kuwait',
    // kg: 'Kyrgyzstan',
    // la: 'Laos',
    // lv: 'Latvia',
    // lb: 'Lebanon',
    // ls: 'Lesotho',
    // lr: 'Liberia',
    // ly: 'Libya',
    // li: 'Liechtenstein',
    // lt: 'Lithuania',
    // lu: 'Luxembourg',
    // mo: 'Macau',
    // mk: 'Macedonia',
    // mg: 'Madagascar',
    // mw: 'Malawi',
    // my: 'Malaysia',
    // mv: 'Maldives',
    // ml: 'Mali',
    // mt: 'Malta',
    // mh: 'Marshall Islands',
    // mq: 'Martinique',
    // mr: 'Mauritania',
    // mu: 'Mauritius',
    // yt: 'Mayotte',
    mx: 'Mexico',
    // fm: 'Micronesia',
    // md: 'Moldova',
    // mc: 'Monaco',
    // mn: 'Mongolia',
    // me: 'Montenegro',
    // ms: 'Montserrat',
    // ma: 'Morocco',
    // mz: 'Mozambique',
    // na: 'Namibia',
    // nr: 'Nauru',
    // np: 'Nepal',
    // nl: 'Netherlands',
    // nc: 'New Caledonia',
    // pg: 'New Guinea',
    // nz: 'New Zealand',
    // ni: 'Nicaragua',
    // ne: 'Niger',
    // ng: 'Nigeria',
    // nu: 'Niue',
    // nf: 'Norfolk Island',
    // kp: 'North Korea',
    // mp: 'Northern Mariana Islands',
    // no: 'Norway',
    // om: 'Oman',
    // pk: 'Pakistan',
    // pw: 'Palau',
    // ps: 'Palestine',
    // pa: 'Panama',
    // py: 'Paraguay',
    // pe: 'Peru',
    // ph: 'Philippines',
    // pn: 'Pitcairn Islands',
    // pl: 'Poland',
    // pt: 'Portugal',
    // pr: 'Puerto Rico',
    // qa: 'Qatar',
    // re: 'Reunion',
    // ro: 'Romania',
    // ru: 'Russia',
    // rw: 'Rwanda',
    // sh: 'Saint Helena',
    // kn: 'Saint Kitts and Nevis',
    // lc: 'Saint Lucia',
    // pm: 'Saint Pierre',
    // vc: 'Saint Vincent',
    // ws: 'Samoa',
    // sm: 'San Marino',
    // gs: 'Sandwich Islands',
    // st: 'Sao Tome',
    // sa: 'Saudi Arabia',
    // sn: 'Senegal',
    // rs: 'Serbia',
    // sc: 'Seychelles',
    // sl: 'Sierra Leone',
    // sg: 'Singapore',
    // sk: 'Slovakia',
    // si: 'Slovenia',
    // sb: 'Solomon Islands',
    // so: 'Somalia',
    // za: 'South Africa',
    // kr: 'South Korea',
    es: 'Spain',
    // lk: 'Sri Lanka',
    // sd: 'Sudan',
    // sr: 'Suriname',
    // sj: 'Svalbard',
    // sz: 'Swaziland',
    // se: 'Sweden',
    // ch: 'Switzerland',
    // sy: 'Syria',
    // tw: 'Taiwan',
    // tj: 'Tajikistan',
    // tz: 'Tanzania',
    // th: 'Thailand',
    // tl: 'Timorleste',
    // tg: 'Togo',
    // tk: 'Tokelau',
    // to: 'Tonga',
    // tt: 'Trinidad',
    // tn: 'Tunisia',
    // tr: 'Turkey',
    // tm: 'Turkmenistan',
    // tv: 'Tuvalu',
    // ug: 'Uganda',
    // ua: 'Ukraine',
    // ae: 'United Arab Emirates',
    // us: 'United States',
    // uy: 'Uruguay',
    // um: 'Us Minor Islands',
    // vi: 'Us Virgin Islands',
    // uz: 'Uzbekistan',
    // vu: 'Vanuatu',
    // va: 'Vatican City',
    ve: 'Venezuela'
    // vn: 'Vietnam',
    // wf: 'Wallis and Futuna',
    // eh: 'Western Sahara',
    // ye: 'Yemen',
    // zm: 'Zambia',
    // zw: 'Zimbabwe'
  },
  countryCallCodes: {
    bd: 880,
    be: 32,
    bf: 226,
    bg: 359,
    ba: 387,
    bb: 1,
    wf: 681,
    bm: 1,
    bn: 673,
    bo: 591,
    bh: 973,
    bi: 257,
    bj: 229,
    bt: 975,
    jm: 1,
    bw: 267,
    ws: 685,
    br: 55,
    bs: 1,
    by: 375,
    bz: 501,
    ru: 7,
    rw: 250,
    rs: 381,
    tl: 670,
    re: 262,
    tm: 993,
    tj: 992,
    ro: 40,
    tk: 690,
    gw: 245,
    gu: 1,
    gt: 502,
    gs: 500,
    gr: 30,
    gq: 240,
    gp: 590,
    jp: 81,
    gy: 592,
    gf: 594,
    ge: 995,
    gd: 1,
    gb: 44,
    ga: 241,
    sv: 503,
    gn: 224,
    gm: 220,
    gl: 299,
    gi: 350,
    gh: 233,
    om: 968,
    tn: 216,
    jo: 962,
    hr: 385,
    ht: 509,
    hu: 36,
    hk: 852,
    hn: 504,
    ve: 58,
    pr: 1,
    ps: 970,
    pw: 680,
    pt: 351,
    sj: 47,
    py: 595,
    iq: 964,
    pa: 507,
    pf: 689,
    pg: 675,
    pe: 51,
    pk: 92,
    ph: 63,
    pn: 870,
    pl: 48,
    pm: 508,
    zm: 260,
    eh: 212,
    ee: 372,
    eg: 20,
    za: 27,
    ec: 593,
    it: 39,
    vn: 84,
    sb: 677,
    et: 251,
    so: 252,
    zw: 263,
    sa: 966,
    es: 34,
    er: 291,
    me: 382,
    md: 373,
    mg: 261,
    ma: 212,
    mc: 377,
    uz: 998,
    mm: 95,
    ml: 223,
    mo: 853,
    mn: 976,
    mh: 692,
    mk: 389,
    mu: 230,
    mt: 356,
    mw: 265,
    mv: 960,
    mq: 596,
    mp: 1,
    ms: 1,
    mr: 222,
    ug: 256,
    tz: 255,
    my: 60,
    mx: 52,
    il: 972,
    fr: 33,
    io: 246,
    sh: 290,
    fi: 358,
    fj: 679,
    fk: 500,
    fm: 691,
    fo: 298,
    ni: 505,
    nl: 31,
    no: 47,
    na: 264,
    vu: 678,
    nc: 687,
    ne: 227,
    nf: 672,
    ng: 234,
    nz: 64,
    np: 977,
    nr: 674,
    nu: 683,
    ck: 682,
    ci: 225,
    ch: 41,
    co: 57,
    cw: 599,
    cn: 86,
    cm: 237,
    cl: 56,
    cc: 61,
    ca: 1,
    cg: 242,
    cf: 236,
    cd: 243,
    cz: 420,
    cy: 357,
    cx: 61,
    cr: 506,
    cv: 238,
    cu: 53,
    sz: 268,
    sy: 963,
    kg: 996,
    ke: 254,
    sr: 597,
    ki: 686,
    kh: 855,
    kn: 1,
    km: 269,
    st: 239,
    sk: 421,
    kr: 82,
    si: 386,
    kp: 850,
    kw: 965,
    sn: 221,
    sm: 378,
    sl: 232,
    sc: 248,
    kz: 7,
    ky: 1,
    sg: 65,
    se: 46,
    sd: 249,
    do: 1,
    dm: 1,
    dj: 253,
    dk: 45,
    vg: 1,
    de: 49,
    ye: 967,
    dz: 213,
    us: 1,
    uy: 598,
    yt: 262,
    um: 1,
    lb: 961,
    lc: 1,
    la: 856,
    tv: 688,
    tw: 886,
    tt: 1,
    tr: 90,
    lk: 94,
    li: 423,
    lv: 371,
    to: 676,
    lt: 370,
    lu: 352,
    lr: 231,
    ls: 266,
    th: 66,
    tg: 228,
    td: 235,
    tc: 1,
    ly: 218,
    va: 379,
    vc: 1,
    ae: 971,
    ad: 376,
    ag: 1,
    af: 93,
    ai: 1,
    vi: 1,
    is: 354,
    ir: 98,
    am: 374,
    al: 355,
    ao: 244,
    as: 1,
    ar: 54,
    au: 61,
    at: 43,
    aw: 297,
    in: 91,
    ax: 358,
    az: 994,
    ie: 353,
    id: 62,
    ua: 380,
    qa: 974,
    mz: 258
  }
}
