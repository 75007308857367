<template>
  <div class="profile-container">
      <div v-if="!loadingUser" style="height: 550px">
        <div class="top-image">
            <div class="image-circle secondary">
                <div class="img-container">
                    <img
                        data-position="right center"
                        class="logo"
                        :src="profilePicOfUser"
                        style="width: 150px; height: 150px; border-radius: 100%"
                    >
                    <div class="image__overlay"
                    :class="{'disabled':(isLoadingAuthentication || isLoadingSendEmail || isLoadingChangingPass || passwordChangeStatus || isLoadingChange)}">
                        <p class="image__description"><strong>{{$t('profile.uploadPhoto')}}</strong></p>
                        <input type="file" name="file" id="file" class="inputfile" @change="changeProfilePic" />
                    </div>
                </div>
            </div>
        </div>

        <h3 style="margin-top: 10px">{{user.email}}</h3>
        <div v-if="!emailSent" class="dimmer">
            <div
                class="form-content"
                :class="{'ui form blue medium double loading':(isLoadingChange || isLoadingAuthentication || isLoadingSendEmail || isLoadingChangingPass)}">
                <form
                    :class="{'disabled':(isLoadingChange || isLoadingAuthentication || isLoadingSendEmail || isLoadingChangingPass || passwordChangeStatus)}">
                    <div class="name field">
                        <div class="label-field">
                            <label style="font-size: 16px; color: #323232;">{{$t('profile.name')}}</label>
                        </div>
                        <div class="input-field">
                            <div class="ui input" style="width: 100%">
                                <input autocomplete="new-password" type="text" :placeholder="$t('profile.name')" v-model="firstNameOfUser">
                            </div>
                        </div>
                    </div>
                    <div class="last-name field">
                        <div class="label-field">
                            <label style="font-size: 16px; color: #323232;">{{$t('profile.lastName')}}</label>
                        </div>
                        <div class="input-field">
                            <div class="ui input" style="width: 100%">
                                <input autocomplete="new-password" type="text" :placeholder="$t('profile.lastName')" v-model="lastNameOfUser">
                            </div>
                        </div>
                    </div>
                    <div class="phone field">
                        <div class="label-field">
                            <label style="font-size: 16px; color: #323232;">{{$t('profile.phone')}}</label>
                        </div>
                        <div class="input-field">
                          <div id="countryCodes" style="width: 53%; height: inherit; max-height: inherit !important">
                            <Select
                              v-model="countryCodePhoneOfUser"
                              :search="true"
                              :placeholder="$t('profile.code')"
                              :options="countriesCodeInfoToShow"
                              :selection="true"
                              :fluid="false"
                            />
                          </div>
                          <div class="ui input" style="width: 47%">
                              <input
                                type="text"
                                placeholder="$t('profile.phone')"
                                v-model="phoneNumberOfUser"
                                :maxlength="maxPhoneLength"
                                autocomplete="new-password">
                          </div>
                        </div>
                    </div>
                    <div class="identity-doc field">
                        <div class="label-field">
                            <label style="font-size: 16px; color: #323232;">{{$t('profile.id')}}</label>
                        </div>
                        <div class="input-field">
                          <div style="width: 30%; height: inherit; max-height: inherit !important">
                            <Select
                              id="docTypes"
                              v-model="identityDocTypeOfUser"
                              :search="true"
                              :placeholder="$t('profile.type')"
                              :options="IdentityDocumentTypesForSelect"
                              :selection="true"
                              :fluid="false"
                            />
                          </div>
                          <div class="ui input" style="width: 70%">
                              <input type="text" :placeholder="$t('profile.id')" v-model="identityDocOfUser">
                          </div>
                        </div>
                    </div>

                </form>
            </div>

            <div v-if="passwordChangeStatus" class="change-password-process" :style="passwordChangeStatus ? 'margin-top: 10px' : ''">

                <div
                    v-if="passwordChangeStatus === 'authenticate'"
                    class="password-authenticator">
                    <span><strong>{{$t('profile.password')}}</strong></span>
                    <div class="ui input">
                        <input
                            style="text-align: center"
                            autocomplete="new-password"
                            type="password"
                            :placeholder="$t('profile.password1')"
                            v-model="passwordChecker"
                            name="new-password"
                            id="new-password"
                        >
                    </div>
                    <span class="forgot-password" @click="sendEmailRestoringPassword">{{$t('forgotPassword')}}</span>
                </div>

                <div
                    v-else-if="passwordChangeStatus === 'changePassword'"
                    class="password-authenticator">
                    <span><strong>{{$t('profile.newPassword')}}</strong></span>
                    <div class="ui input">
                        <input
                            style="text-align: center"
                            autocomplete="new-password"
                            type="password"
                            :placeholder="$t('profile.newPassword1')"
                            v-model="passwordChecker"
                            name="new-password"
                            id="new-password"
                        >
                    <i :class="[isEyeOpen ? 'eye icon': 'eye slash icon', 'eye-password']"
                      @click="changeEye"></i>
                    </div>
                    <div class="level-container" style="margin-bottom: 0">
                        <div :class="strength_level" style="margin-bottom: 0"></div>
                    </div>
                </div>

            </div>

            <div class="btn-container" :style="!passwordChangeStatus ? 'margin-top: 20px' : ''">
              <!--  || v$.form.docType.$error -->
                <Button
                    :class="{'disabled' : !isAnyChange ||
                    (v$.form.$errors.length && textForBtn[passwordChangeStatus] === 'Guardar')}"
                    :appearance="classBtnObject"
                    :text="textForBtn[passwordChangeStatus]"
                    color="primary"
                    @clicked="functionsForBtns[passwordChangeStatus]"
                />
            </div>

            <div v-if="!passwordChangeStatus" class="change-password-lickeable-text">
                <h5  @click="passwordChangeStatus = 'authenticate'; v$.$reset()">{{$t('profile.changePassword')}}</h5>
            </div>

        </div>

        <div v-else>
            <h1 class="my-4 mx-5 text-center">{{ $t('checkEmailAndInstructions') }}</h1>
            <h3>{{ $t('recoverPasswordSentEmail') }}</h3>
            <Button
                :appearance="classBtnObject"
                :text="textForBtn[passwordChangeStatus]"
                color="primary"
                @clicked="functionsForBtns[passwordChangeStatus]"
            />
        </div>

      </div>
<!--  || v$.form.docType.$error -->
    <div
      style="width:100%; align-self:center; position: absolute; display: flex; justify-content:center;
      margin-top: -40px; margin-left: -30px"
      v-if="v$.form.$errors">
      <span class="text-danger"  v-if="v$.form.firstName.$error">
        {{v$.form.firstName.$errors[0].$message }}
      </span>
      <span v-else-if="v$.form.lastName.$error" class="text-danger">
        {{v$.form.lastName.$errors[0].$message }}
      </span>
      <span v-else-if="v$.form.phone.$error" class="text-danger">
        {{ v$.form.phone.$errors[0].$message}}
      </span>
      <span v-else-if="v$.form.doc.$error" class="text-danger">
        {{ v$.form.doc.$errors[0].$message}}
      </span>
      <!-- <span v-else-if="v$.form.docType.$error" class="text-danger">
        {{ v$.form.docType.$errors[0].$message}}
      </span> -->
    </div>
    <!-- <span v-else-if="error" class="text-danger">{{$t('errors.emailTaken')}}</span> -->
    <div
        v-if="!loadingUser"
        class="acceptance-of-terms-and_conditions">
        <span>{{$t('footer.terms1')}} <strong class="terms-and-conditions"><a :href="urlTandC" target="_blank">{{$t('footer.terms2')}}</a></strong></span>
    </div>
  </div>
</template>

<script>
import { me, updateProfile, changeProfilePic } from '@/api/user'
import Button from '@/components/form/Button'
import { forgotPassword } from '@/api/auth'
import { useVuelidate } from '@vuelidate/core'
// minLength
import { required, minLength, helpers, numeric, alphaNum, maxLength } from '@vuelidate/validators/dist/raw.esm'
import globals from '@/utils/globals'
import { mapGetters, mapActions } from 'vuex'
// import { SuiDropdown } from 'vue-fomantic-ui'
import Select from '@/components/form/Select'
import codes from '../../utils/countryCodes'

export default {
  components: {
    Button,
    Select
    // SuiDropdown
  },
  setup () { return { v$: useVuelidate() } },
  created () {
    this.$store.dispatch('residential/setActiveResidential', {})
    this.IdentityDocumentTypes.forEach(type => { this.IdentityDocumentTypesForSelect.push({ id: type, description: type }) })
  },
  updated () {
    let countryCodeField = document.getElementById('countryCodes')
    if (countryCodeField) {
      countryCodeField = countryCodeField.children[0].children[0]
      countryCodeField.setAttribute('autocomplete', 'new-password')
    }
  },
  data () {
    return {
      urlTandC: globals.urlTermsAndConditions,
      form: {
        profilePic: null,
        profilePicFile: null,
        firstName: '',
        lastName: '',
        password: '',
        countryCode: '',
        phone: '',
        docType: '',
        doc: ''
        // passwordChecker: ''
      },
      passwordChecker: '',
      user: {},
      token: null,
      isAnyChange: false,
      loadingUser: false,
      isLoadingAuthentication: false,
      isLoadingSendEmail: false,
      isLoadingChangingPass: false,
      isLoadingChange: false,
      passwordChangeStatus: '',
      emailSent: false,
      strength: 0,
      textForBtn: {
        '': this.$t('buttons.save'),
        authenticate: this.$t('buttons.checkPassword'),
        changePassword: this.$t('buttons.changePassword'),
        returnToEdition: this.$t('buttons.goBack')
      },
      functionsForBtns: {
        '': this.saveChanges,
        authenticate: this.authenticatePassword,
        changePassword: this.changePassword,
        returnToEdition: this.returnToEditionForm
      },
      countryCodes: codes.countryCodes,
      countryCallCodes: codes.countryCallCodes,
      IdentityDocumentTypes: ['CC', 'CE', 'PEP', 'DNI', 'SCR', 'PA'],
      IdentityDocumentTypesForSelect: [],
      maxDocLength: 10,
      maxPhoneLength: 13
    }
  },
  validations () {
    return {
      form: {
        firstName: {
          required: helpers.withMessage(this.$t('errors.profile.nameRequired'), required)
        },
        lastName: {
          required: helpers.withMessage(this.$t('errors.profile.lastNameRequired'), required)
        },
        phone: {
          required: helpers.withMessage(this.$t('errors.profile.phoneRequired'), required),
          numeric: helpers.withMessage(this.$t('errors.profile.validPhone'), numeric),
          maxLength: helpers.withMessage(`${this.$t('errors.profile.phoneMax')} ${this.maxPhoneLength} ${this.$t('errors.profile.digits')}`, maxLength(this.maxPhoneLength))
        },
        doc: {
          // required: helpers.withMessage(this.$t('errors.profile.idRequired'), required),
          alphaNum: helpers.withMessage(this.$t('errors.profile.validId'), alphaNum),
          maxLength: helpers.withMessage(`${this.$t('errors.profile.idMax')} ${this.maxDocLength} ${this.$t('errors.profile.characters')}`, maxLength(this.maxDocLength))
        }
        // docType: {
        //   // required: helpers.withMessage(this.$t('errors.profile.idType'), required),
        //   validType: helpers.withMessage(this.$t('errors.profile.idType'),
        //     function () {
        //       const divIndentityDocType = document.getElementById('docTypes')
        //       const inputIdentityDoc = divIndentityDocType.children[0].value
        //       const divTxtIdentityDoc = divIndentityDocType.children[1].value.innerText
        //       const value = inputIdentityDoc + divTxtIdentityDoc
        //       return !this.IdentityDocumentTypes.includes(value.trim().toUpperCase())
        //     })
        // }
      },
      passwordChecker: {
        required: helpers.withMessage(this.$t('validations.password.required'), required),
        numeric: helpers.withMessage(this.$t('validations.password.numericOnly'), numeric),
        minLength: helpers.withMessage(this.$t('validations.password.miniumCharacters', { number: globals.passwordRules.minLength }), minLength(globals.passwordRules.minLength))
        // upperCase: helpers.withMessage(this.$t('validations.password.uppercaseCharacters'), (value) => /(?=.*[A-Z])/.test(value)
        // ),
        // numberIn: helpers.withMessage(this.$t('validations.password.numberCharacters'), (value) => /(?=.*\d)/.test(value))
      }
    }
  },
  computed: {

    countriesCodeInfoToShow () {
      const coutriesFormatted = []
      for (const abbrv of Object.keys(this.countryCodes)) {
        coutriesFormatted.push({ id: abbrv, description: `${abbrv.trim()} (+${this.countryCallCodes[abbrv]})`, flag: abbrv.trim() })
      }
      return coutriesFormatted
    },

    firstNameOfUser: {
      get () {
        return this.form.firstName
      },
      set (newVal) {
        this.form.firstName = newVal
        this.isAnyChange = true
        this.v$.form.firstName.$model = newVal
      }
    },

    lastNameOfUser: {
      get () {
        return this.form.lastName
      },
      set (newVal) {
        this.form.lastName = newVal
        this.isAnyChange = true
        this.v$.form.lastName.$model = newVal
      }
    },

    profilePicOfUser: {
      get () {
        return this.form.profilePic ? this.form.profilePic : this.user.avatar
      },
      set (newVal) {
        this.form.profilePic = newVal
      }
    },

    countryCodePhoneOfUser: {
      get () {
        return this.form.countryCode ? this.form.countryCode : this.user.phoneCountryCode
      },
      set (newVal) {
        this.form.countryCode = newVal
        this.isAnyChange = true
      }
    },

    phoneNumberOfUser: {
      get () {
        return this.form.phone
      },
      set (newVal) {
        this.form.phone = newVal
        this.isAnyChange = true
        this.v$.form.phone.$model = newVal
      }
    },

    identityDocOfUser: {
      get () {
        return this.form.doc
      },
      set (newVal) {
        this.form.doc = newVal
        this.isAnyChange = true
        this.v$.form.doc.$model = newVal
      }
    },

    identityDocTypeOfUser: {
      get () {
        return this.form.docType ? this.form.docType : this.user.documentType
      },
      set (newVal) {
        // console.log(newVal)
        this.form.docType = newVal
        this.isAnyChange = true
        // this.v$.form.docType.$model = newVal.value
      }
    },

    classBtnObject () {
      return {
        height: '29px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        padding: '7px 2.4rem !important'
      }
    },

    strength_level: function () {
      return `level level-${this.strength}`
    },

    ...mapGetters('oauth', [
      'authToken'
    ]),

    ...mapGetters('user', [
      'meAdminInfo'
    ])
  },
  watch: {
    passwordChecker: function () {
      // console.log('printing errors -----------')
      // console.log('num of errors ', this.v$.passwordChecker.$errors.length)
      // console.log('errors: ', this.v$.passwordChecker.$errors)
      this.v$.passwordChecker.$touch()
      this.error = false
      // this.strength = this.v$.passwordChecker.$errors.length === 1 ? 1 : 3 - this.v$.passwordChecker.$errors.length
      this.strength = this.passwordChecker === '' || isNaN(this.passwordChecker) ? 1 : 3 - this.v$.passwordChecker.$errors.length
      this.isAnyChange = true
    }
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    ...mapActions('user', ['setMeAdminInfo']),
    changeEye () {
      const passwordField = document.querySelector('#new-password')
      this.isEyeOpen = !this.isEyeOpen
      if (passwordField.getAttribute('type') === 'password') {
        passwordField.setAttribute('type', 'text')
      } else {
        passwordField.setAttribute('type', 'password')
      }
    },

    saveChanges () {
      this.v$.form.$touch()

      const formValid = !(this.v$.form.$error)

      if (formValid) {
        this.setLoading(true)
        let data = {
          firstName: this.firstNameOfUser,
          lastName: this.lastNameOfUser,
          documentType: this.identityDocTypeOfUser.text,
          document: `${this.identityDocOfUser}`,
          phoneCountryCode: `${this.countryCodePhoneOfUser.text.match(/\d+/g)[0]}`,
          countryCode: `${this.countryCodePhoneOfUser.value}`,
          phoneNumber: `${this.phoneNumberOfUser}`
        }

        updateProfile(data).then(r => {
          if (!this.profilePicFile) {
            // console.log('r data', r)
            this.setMeAdminInfo(r.data)
            this.$swal(this.$t('profile.updateSuccess'), '', 'success')
          } else {
            data = new FormData()
            data.append('image', this.profilePicFile)
            return changeProfilePic(data)
          }
        }).then(r => {
          if (r) {
            this.setMeAdminInfo(r.data)
            this.$swal(this.$t('profile.updateSuccess'), '', 'success')
          }
        }).catch(() => {
          // console.log(error)
          this.$swal(this.$t('errors.somethingHappened'), '', 'error')
        }).finally(() => {
          this.setLoading(false)
        })
      }
    },

    authenticatePassword () {
      // console.log('correct password')
      // this.isLoadingAuthentication = true
      this.setLoading(true)
      // const data = {
      //   email: this.user.email,
      //   password: this.passwordChecker
      // }
      const data = {
        token: this.token,
        password: this.passwordChecker
      }
      this.$store.dispatch('oauth/validatePassword', data).then(() => {
        // console.log('correct password')
        this.passwordChangeStatus = 'changePassword'
        this.passwordChecker = ''
      }).catch(() => {
        this.$swal(this.$t('errors.profile.wrongPassword'), '', 'error')
      }).finally(() => {
        // this.isLoadingAuthentication = false
        this.setLoading(false)
      })
    },

    changePassword () {
      this.v$.passwordChecker.$touch()
      const passwordValid = !(this.v$.passwordChecker.$error)

      if (passwordValid) {
        this.setLoading(true)

        const payload = {
          token: this.token,
          password: this.passwordChecker
        }
        this.$store.dispatch('oauth/resetPassword', payload).then(() => {
          // this.$router.replace({ path: '/' })
          this.$swal(this.$t('profile.changePassword'), '', 'success')
          this.passwordChangeStatus = ''
        }).catch(() => {
          this.$swal(this.$t('errors.somethingHappened'), '', 'error')
        }).finally(() => {
          // this.isLoadingChangingPass = false
          this.setLoading(false)
          this.passwordChecker = ''
          this.isAnyChange = false
        })
      } else {
        // this.$swal(this.$t('errors.profile.strongerPassword'), '', 'error')
        this.$swal(this.$t('errors.profile.strongerPassword'), '', 'error')
      }
    },

    returnToEditionForm () {
      // console.log('returning')
      // console.log(this.passwordChangeStatus)
      this.emailSent = !this.emailSent
      this.passwordChangeStatus = ''
      // console.log('is email sent after function', this.emailSent)
    },

    sendEmailRestoringPassword () {
      // this.isLoadingSendEmail = true
      this.setLoading(true)
      forgotPassword(this.user.email).then(() => {
        this.emailSent = true
        this.passwordChangeStatus = 'returnToEdition'
        this.passwordChecker = ''
        this.setLoading(false)
      }).catch(() => {
        this.emailNotExists = true
        this.setLoading(false)
      }).finally(() => {
        this.setLoading(false)
        // this.isLoadingSendEmail = false
      })
    },

    changeProfilePic (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      const pic = files[0]
      if (!['jpg', 'png', 'jpeg'].includes(pic.type.split('/')[1])) {
        this.$swal(this.$t('errors.profile.imageFormats'), '', 'error')
        return
      }
      this.profilePicFile = pic
      this.profilePicOfUser = URL.createObjectURL(pic)
      this.isAnyChange = true
    },

    configureInfo (meAdminInfo) {
      this.user = meAdminInfo
      this.firstNameOfUser = this.user.firstName
      this.lastNameOfUser = this.user.lastName
      this.profilePicOfUser = this.user.avatar ? this.user.avatar : '/img/icon_profile_active.11de94eb.svg'
      this.countryCodePhoneOfUser = {
        value: this.user.countryCode,
        text: `${this.user.countryCode} (+${this.user.phoneCountryCode})`,
        flag: this.user.countryCode
      }
      this.identityDocTypeOfUser = { value: this.user.documentType, text: this.user.documentType }
      this.token = this.authToken
      this.phoneNumberOfUser = this.user.phoneNumber
      this.identityDocOfUser = this.user.document
      this.isAnyChange = false
    }
  },

  beforeMount () {
    if (Object.keys(this.meAdminInfo).length === 0) {
      this.loadingUser = true
      this.setLoading(true)
      me().then(r => {
        this.loadingUser = false
        this.setMeAdminInfo(r.data)
        this.configureInfo(this.meAdminInfo)
      }).finally(() => {
        this.setLoading(false)
        this.isAnyChange = false
      })
    } else {
      this.configureInfo(this.meAdminInfo)
    }
  }
}
</script>

<style scoped>
.profile-container {
    padding-top: 50px;
    width: 100%;
    padding-right: 60px;
    height: 80vh;
}

.top-image {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.image-circle {
    border-radius: 100%;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-content {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
}

form {
    width: 550px;
    height: 100%;
    /* background-color: brown; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.field {
    width: 100%;
    display: flex;
    align-items: center;
    /* margin-bottom: 12px; */
}

.label-field, .input-field {
    width: 50%;
    height: 100%;
    display: flex;
}

.label-field {
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
}

.input-field {
    justify-content: center;
    align-items: center;
}

/* .input-field > div {
    width: 100%;
} */

.btn-container {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.change-password-lickeable-text {
    display: flex;
    justify-content: center;
    align-items: center;
}

.change-password-lickeable-text > h5:hover {
    cursor: pointer;
    text-decoration: underline;
}

.change-password-process {
    height: 90px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.password-authenticator {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: inherit;
}

.acceptance-of-terms-and_conditions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 20px;*/
    /* position: fixed !important;*/
}

.terms-and-conditions {
    color: #436f98;
    cursor: pointer;
    text-decoration: underline;
}

.forgot-password {
    cursor: pointer;
}

.forgot-password:hover {
    text-decoration: underline;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

  .level-container {
    display: flex !important;
    max-width: inherit;
    width: 100% !important;
    margin-top: 0.5rem !important;
  }
  .level-container .level {
    height: 5px !important;
    transition: all 0.5s !important;
  }
  .level-container .level-1 {
    background-color: #ff1a1a !important;
    width: 3.3rem !important;
  }
  .level-container .level-2 {
    background-color: yellow !important;
    width: 8.5rem !important;
  }
  /* .level-container .level-3 {
    background-color: limegreen !important;
    width: 12.6rem !important;
  } */
  .level-container .level-3 {
    background-color: cadetblue !important;
    width: 16.6rem !important;
  }

  .image__overlay {
    position: absolute;
    top: 0;
    width: 150px;
    height: 150px;
    background: rgba(26, 41, 107, 0.6);
    font-family: inherit;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s;
    border-radius: 100%;
    border-style: dotted;
}

.image__overlay > * {
    transform: translateY(20px);
    transition: transform 0.25s
}

.image__overlay:hover {
    opacity: 1;
    cursor: pointer;
}

.image__overlay:hover > * {
    transform: translateY(0);
}

.img-container {
    position: relative;
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
    margin-left: 10px !important;
    margin-right: 10px;
    margin-top: 10px;
    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;*/
    /*box-shadow: 0px 5px 10px -2px rgb(0 0 0 / 10%) !important;*/
    display: flex;
    align-items: center;
    justify-content: center;
}

.image__description {
  font-size: 15px;
}

.inputfile {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  margin-top: -40px;
  cursor: pointer;
  /* z-index: -1; */
}

  .eye-password {
    color:#676565;
    text-shadow: 1px 1px 1px #ccc;
    font-size: 1.5em;
    position: absolute;
    right: 0;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 5px;
    align-content: center;
    align-items: center;
  }
</style>
