<template>
  <SuiDropdown
    :selection="selection"
    fluid
    :placeholder="placeholder || $t('form.select')"
    :clearable="clearable"
    :multiple="multiple"
    :search="search"
    :searchInMenu="searchInMenu"
    :options="itemsArray"
    :pointing="pointing"
    :button="button"
    :disabled="disabled"
    :icon="icon || 'dropdown'"
  />
</template>

<script>
import { SuiDropdown } from 'vue-fomantic-ui'

export default {
  name: 'Select',
  components: {
    SuiDropdown
  },
  props: {
    value: String,
    options: Array,
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    search: {
      type: Boolean,
      required: false,
      default: false
    },
    searchInMenu: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined
    },
    pointing: {
      type: String,
      required: false,
      default: 'top left'
    },
    selection: {
      type: Boolean,
      required: false,
      default: true
    },
    button: {
      type: Boolean,
      required: false,
      default: false
    },
    fluid: {
      type: Boolean,
      required: false,
      default: true
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    itemsArray () {
      return this.options.map(x => {
        const item = {
          value: x.id,
          text: x.description
        }
        if (x.photoUrl) {
          item.image = { src: x.photoUrl }
        }
        if (x.avatar) {
          item.image = { avatar: true, src: x.avatar }
        }
        if (x.icon) {
          item.label = { icon: x.icon }
        }
        if (x.flag) {
          item.flag = x.flag
        }
        return item
      })
    }
  }
}
</script>

<style scoped>

</style>
