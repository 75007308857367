<template>
  <!-- :style="appearance" -->
  <div>
    <button
      class="ui button"
      type="button"
      :style="appearance"
      :disabled="disabled"
      :class="{
        'small-button': small,
        [color]: true,
        'primary-button':color === 'primary',
        'secondary-button secondary': color ==='secondary',
        'tertiary-button': color === 'third-color',
        'danger-button': color === 'danger',
        'gray-button': color === 'gray',
        'green-button': color === 'message',
        'new-button': color === 'new'
      }"
      @click.prevent="onClick"
    >
      <i v-if="icon" class="icon" :class="{ [icon]: true }"></i>
      <span :class="{'text-small': small }">{{ text }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: {
      required: true,
      type: String
    },
    color: {
      required: true,
      type: String
    },
    isWaiting: {
      required: false,
      type: Boolean,
      default: false
    },
    small: {
      required: false,
      type: Boolean,
      default: false
    },
    appearance: {
      required: false,
      type: Object
    },
    icon: {
      required: false,
      type: String
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['clicked'],
  methods: {
    onClick () {
      this.$emit('clicked')
    }
  }
}
</script>

<style scoped>
  button {
    color: white;
  }

  button > span {
    font-size: .9rem;
    display: inline-block;
  }

  .primary-button {
    padding: 7px 2.4rem; /*!important */
    text-transform: none !important;
    letter-spacing: normal;
    border-radius: 4px !important;
    transition: background-color .5s ease !important;
  }

  .primary-button:hover{
    /* background-color: #2e608f !important;*/
    background-color: #436f98 !important;
  }
  .new-button {
    background-color: #00ccff;
    padding: 7px 2.4rem; /*!important */
    text-transform: none !important;
    letter-spacing: normal;
    border-radius: 4px !important;
    transition: background-color .5s ease !important;
  }

  .new-button:hover{
    /* background-color: #2e608f !important;*/
    background-color: #00aeda !important;
  }

  .secondary-button, .tertiary-button, .danger {
    padding: 6px 1.2rem;
    /*max-height: 28px;*/
    text-transform: none !important;
    letter-spacing: normal;
    border-radius: 4px;
    transition: background-color .5s ease !important;
  }

  .danger {
    background-color: #dc3545;
    color: white;
    height: inherit !important;
  }

  .secondary-button:hover {
    background-color: #1d92c4 !important
  }

  .tertiary-button:hover {
    background-color: #54a1c0 !important;
  }

  .danger:hover {
    opacity: 0.8;
  }

  .small-button {
    max-height: 15px !important;
    min-width: 44px !important;
    padding: 0px 5px !important;
  }

  .text-small{
    font-size: 9px !important;
    vertical-align: middle;
  }

  .primary-button:hover{
    background-color: #2c97c5 !important;
  }

  .green-button{
    background-color: var(--messaging-color) !important;
    color: white;
    padding: 7px 2.4rem; /*!important */
    text-transform: none !important;
    letter-spacing: normal;
    border-radius: 4px !important;
    box-shadow: none;
    transition: background-color .5s ease !important;
  }

  .green-button:hover{
    /* background-color: #2e608f !important;*/
    color: white;
    background-color: #5dc204 !important;
  }

  .danger:hover, .danger:focus {
    background-color: #dc3545;
    color: white;
  }

  .gray-button {
    background-color: #fff;
    text-transform: none !important;
    letter-spacing: normal;
    border-radius: 4px !important;
    transition: background-color .5s ease !important;
    border: 1px solid rgba(34,36,38,.15);
    padding: 7px 2.4rem
  }
</style>
